<template>
  <div class="pr-3">
    <vs-table
      v-if="events"
      stripe
      class="pb-4"
      :data="events"
      no-data-text="Nenhum evento disponível"
    >
      <template slot="header">
        <div class="d-flex justify-content-between align-items-center w-100 my-3">
          <h3 class="text-dark ml-4">
            Lista de Eventos
          </h3>
        </div>
      </template>

      <template slot="thead">
        <vs-th>
          Nome
        </vs-th>

        <vs-th>
          Data
        </vs-th>

        <vs-th>
          <p class="text-center my-0 w-100">
            Enviados / Disponíveis
          </p>
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(event, eventIdx) in data"
          :key="eventIdx"
          class="border-top"
        >
          <vs-td class="py-1">
            {{ event.name }}
          </vs-td>

          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia: </b>

              {{ dateFormated(event.dateStart) }}
            </p>

            <p
              v-if="event.timeStart"
              class="mb-0"
            >
              <b>Hora: </b>

              {{ timeFormated(event.timeStart.value.totalMilliseconds) }}h
            </p>
          </vs-td>

          <vs-td class="py-1 text-center">
            <span>{{ event.tickets }}</span>

            /

            <span
              v-if="availableTickets(event.credits, event.tickets) === 'infinity'"
              class="material-icons"
              :style="{ fontSize: '1em', verticalAlign: 'middle' }"
            >
              all_inclusive
            </span>

            <span v-else>
              {{ availableTickets(event.credits, event.tickets) }}
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
/* Helpers */
import moment from 'moment';

/* Services */
import Event from '@/services/event';

const eventService = new Event();

export default {
  name: 'EventList',
  data: () => ({
    /* Data */
    events: [],
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      return token[0].replace('tk=', '');
    },
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    /* API */
    getEvents() {
      if (this.checkItemAccess(['Events'])) {
        this.$store.dispatch('set_isLoadingActive', true);

        eventService
          .getEvents(this.token, this.ownerId)
          .then((res) => {
            this.events = res;

            this.$store.dispatch('set_events', res);
            this.$store.dispatch('set_isLoadingActive', false);
          })
          .catch((err) => {
            this.$store.dispatch('set_isLoadingActive', false);

            alert(err);
          });
      }
    },
    /* Filters */
    dateFormated(date) {
      if (!date) return 'Não informado';

      return moment(date).format('DD/MM/yyyy');
    },
    timeFormated(time) {
      if (!time) return 'Não informado';

      return moment(time)
        .locale('pt')
        .format('LT');
    },
    /* Helpers */
    availableTickets(credits, tickets) {
      const subtract = credits - tickets;

      return subtract < 0 ? 'infinity' : subtract;
    },
    checkItemAccess(access) {
      if (!access) {
        return true;
      } else {
        let hasAccess = false;

        access.forEach((i) => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            hasAccess = true;
          }
        });

        return hasAccess;
      }
    },
  },
};
</script>
